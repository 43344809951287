import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Carousel from "../../components/UpdatedCarousel";
import Bundle from "./Bundle";
import DoubleBundle from "./DoubleBundle";
import { useState } from "react";
import { toast } from "react-toastify";
import { getCart, saveCart } from "../../utils/localstorage";
import BundleAddToCartForm from "./BundleAddToCartForm";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import TeamBundle from "./TeamBundle";
import VideoModal from "../../components/VideoModal";
const PersonalBundleImage = ["personal-bundle-1.png", "personal-bundle-2.png"];
const FamilyBundleImage = ["family-bundle-1.png", "family-bundle-2.png"];
const TeamBundleImage = ["team-bundle-1.png", "team-bundle-2.png"];

export default function Page() {
  let navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const [caffeineFree, setCaffeineFree] = useState(false);
  const [caffeineFreeForDouble, setCaffeineFreeForDouble] = useState(false);
  const [caffeineFreeForTeam, setCaffeineFreeForTeam] = useState(false);
  const [isOpenPersonalVideo, setIsOpenPersonalVideo] = useState(false);
  const [isOpenFamilyVideo, setIsOpenFamilyVideo] = useState(false);
  const [isOpenTeamVideo, setIsOpenTeamVideo] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [page, setPage] = useState(
    localStorage.getItem("currentRoute") || "detail"
  );
  const [prefix, setPrefix] = useState("");
  const [available, setAvailable] = useState("");

  const onChangeCaffeineFree = () => {
    setCaffeineFree(!caffeineFree);
  };

  const onChangeCaffeineFreeDouble = () => {
    setCaffeineFreeForDouble(!caffeineFreeForDouble);
  };

  const onChangeCaffeineFreeTeam = () => {
    setCaffeineFreeForTeam(!caffeineFreeForTeam);
  };

  const handleAddToCart = () => {
    // setPage("website");
    localStorage.setItem("currentRoute", "website");
  };

  const onNextPage = () => {
    const cart = getCart();
    if (
      cart?.filter(
        (c) => c.product.Sku === "RCBurn1" || c.product.Sku === "RCBurn2"
      ).length > 0
    ) {
      navigate("/check-out");
    } else {
      navigate("/bands");
    }
  };
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      {page === "detail" && (
        <>
          <h1 className="pb-4 pl-1 md:py-4 md:pb-0 md:text-6xl text-5xl roboto-medium">
            <span>B</span>
            <span className="text-[#00adef] mr-3">3</span>
            <span className="">Bundles</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-4 md:gap-14 mb-6">
            <div className="relative flex flex-col mt-2 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem]">
              <h1 className="pb-2 block md:pb-0 md:text-[2.7rem] leading-none text-2xl roboto-medium text-white max-w-full w-[calc(100%-130px)] md:w-[calc(100%-190px)]">
                Personal Bundle
              </h1>
              <span className="text-2xl md:text-3xl text-[#00adef] pb-3 md:py-2">
                Save $60
              </span>
              <img
                alt="member-logo"
                src="./assets/images/member-logo.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[60px] md:top-[-35px] md:right-[85px]"
              />
              <img
                alt="30Day Icon"
                src="./assets/images/30days-free.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[-20px] md:top-[-35px] md:right-[-45px]"
              />
              <Bundle />
              {/* <div className="flex flex-row items-start mt-8 md:mt-4">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                    checked={caffeineFree}
                    onChange={onChangeCaffeineFree}
                  />
                  <div class="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label class="text-md leading-6 text-white" id="switch-1-label">
                  Yes, I would like my{" "}
                  <strong>
                    B<span className="text-[#00adef]">3</span> START
                  </strong>{" "}
                  product Caffeine Free.
                </label>
              </div> */}
              <div className="">
                <BundleAddToCartForm
                  isSingle
                  sku="RCBurn1"
                  handleAddToCart={() => handleAddToCart()}
                  caffeineFree={caffeineFree}
                />
              </div>
              <div className="flex gap-4">
                <div className="text-lg md:text-2xl text-white roboto-regular-italic my-auto">
                  As low as $90 month with paypal credit with $0 down and 0%
                  interest
                </div>
                <div className="flex flex-col ml-4">
                  <img
                    alt="paypal credit"
                    src="./assets/images/paypal-credit-t.png"
                    className="w-[170px] m-auto"
                  />
                </div>
              </div>
            </div>
            <div className="block my-auto mb-6 md:mb-auto items-center">
              <Carousel
                swiperImages={PersonalBundleImage}
                className="!h-auto lg:h-auto"
              />
              <VideoModal
                open={isOpenPersonalVideo}
                setOpen={setIsOpenPersonalVideo}
                videoId="1044707469"
              />
            </div>
          </div>

          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-4 md:gap-14 mb-6">
            <div className="relative flex flex-col  mt-2 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem]">
              <h1 className="pb-2 block md:pb-0 md:text-[2.7rem] leading-none text-2xl roboto-medium text-white max-w-full w-[calc(100%-130px)] md:w-[calc(100%-190px)]">
                Family Bundle
              </h1>
              <span className="text-2xl md:text-3xl text-[#00adef] pb-3 md:py-2">
                Save $280
              </span>

              <img
                alt="member-logo"
                src="./assets/images/member-logo.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[60px] md:top-[-35px] md:right-[85px]"
              />
              <img
                alt="30Day Icon"
                src="./assets/images/30days-free.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[-20px] md:top-[-35px] md:right-[-45px]"
              />

              <DoubleBundle />
              {/* <div className="flex flex-row items-start mt-8 md:mt-4">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                    checked={caffeineFreeForDouble}
                    onChange={onChangeCaffeineFreeDouble}
                  />
                  <div class="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label class="text-md leading-6 text-white" id="switch-1-label">
                  Yes, I would like my{" "}
                  <strong>
                    B<span className="text-[#00adef]">3</span> START
                  </strong>{" "}
                  product Caffeine Free.
                </label>
              </div> */}
              <div className="">
                <BundleAddToCartForm
                  sku="RCBurn2"
                  handleAddToCart={() => handleAddToCart()}
                  caffeineFree={caffeineFreeForDouble}
                />
              </div>
              <div className="flex gap-4">
                <div className="text-lg md:text-2xl text-white roboto-regular-italic my-auto">
                  As low as $150 month with paypal credit with $0 down and 0%
                  interest
                </div>
                <div className="flex flex-col ml-4">
                  <img
                    alt="paypal credit"
                    src="./assets/images/paypal-credit-t.png"
                    className="w-[170px] m-auto"
                  />
                </div>
              </div>
            </div>
            <div className="block my-auto mb-6 md:mb-auto items-center">
              <Carousel
                swiperImages={FamilyBundleImage}
                className="!h-auto lg:h-auto"
              />
              <VideoModal
                open={isOpenFamilyVideo}
                setOpen={setIsOpenFamilyVideo}
                videoId="1044707469"
              />
            </div>
          </div>

          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-4 md:gap-14 mb-6">
            <div className="relative flex flex-col  mt-2 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem]">
              <h1 className="pb-2 block md:pb-0 md:text-[2.7rem] leading-none text-2xl roboto-medium text-white max-w-full w-[calc(100%-130px)] md:w-[calc(100%-190px)]">
                Team Bundle
              </h1>
              <span className="text-2xl md:text-3xl text-[#00adef] pb-3 md:py-2">
                Save $650
              </span>

              <img
                alt="member-logo"
                src="./assets/images/member-logo.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[60px] md:top-[-35px] md:right-[85px]"
              />
              <img
                alt="30Day Icon"
                src="./assets/images/30days-free.png"
                className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[-20px] md:top-[-35px] md:right-[-45px]"
              />

              <TeamBundle />
              {/* <div className="flex flex-row items-start mt-8 md:mt-4">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                    checked={caffeineFreeForTeam}
                    onChange={onChangeCaffeineFreeTeam}
                  />
                  <div class="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label class="text-md leading-6 text-white" id="switch-1-label">
                  Yes, I would like my{" "}
                  <strong>
                    B<span className="text-[#00adef]">3</span> START
                  </strong>{" "}
                  product Caffeine Free.
                </label>
              </div> */}
              <div className="">
                <BundleAddToCartForm
                  sku="RCBurn5"
                  handleAddToCart={() => handleAddToCart()}
                  caffeineFree={caffeineFreeForTeam}
                  isTeam
                />
              </div>
              <div className="flex gap-4">
                <div className="text-lg md:text-2xl text-white roboto-regular-italic my-auto">
                  As low as $420 month with paypal credit with $0 down and 0%
                  interest
                </div>
                <div className="flex flex-col ml-4">
                  <img
                    alt="paypal credit"
                    src="./assets/images/paypal-credit-t.png"
                    className="w-[170px] m-auto"
                  />
                </div>
              </div>
            </div>
            <div className="block my-auto mb-6 md:mb-auto items-center">
              <Carousel
                swiperImages={TeamBundleImage}
                className="!h-auto lg:h-auto"
              />
              <VideoModal
                open={isOpenTeamVideo}
                setOpen={setIsOpenTeamVideo}
                videoId="1044707469"
              />
            </div>
          </div>

          <div className="flex text-center lg:mt-8 mt-5 md:justify-center relative items-center justify-center pb-5">
            <Button
              className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto"
              onClick={onNextPage}
            >
              Next
              <div className="inline-block pl-1 relative top-[3px]">
                <ArrowRightIcon
                  fill="white"
                  className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                  aria-hidden="true"
                  color="white"
                />
              </div>
            </Button>
          </div>
        </>
      )}
    </section>
  );
}
