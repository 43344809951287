import { createContext, useEffect, useState } from "react";
import { getCart, saveCart } from "../utils/localstorage";

export const CartContext = createContext();

export function CartContextProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCart(getCart());
  }, []);

  useEffect(() => {
    setCartCount(cart?.length);
  }, [cart?.length]);

  const save = (cartInput) => {
    saveCart(cartInput);
    setCart(cartInput);
  };

  return (
    <CartContext.Provider
      value={{ cart, cartCount, save, showError, setShowError }}
    >
      {children}
    </CartContext.Provider>
  );
}
