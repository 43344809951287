export const gearProductsData = [
  {
    productName: "Exercise Tubing",
    images: ["exercise-tubing-main.png"],
    details: ["B3 Exercise Tubing Set (5 Tubings + attachments)"],
    addToCartSku: "ExerTub",
  },
  {
    productName: "Duffle Bag",
    images: ["duffle-bag-main.png"],
    details: ["B3 Bands Duffle Bag"],
    addToCartSku: "DuffleBag",
  },
];
