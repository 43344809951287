import { Disclosure, Tab } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import SizeChart from "../../components/SizeChart";
import TeamBundleDetails from "./TeamBundleDetails";

const categories = ["Details", "Size Chart"];

export default function TeamBundle() {
  return (
    <div className="w-full sm:px-0">
      <div className="hidden md:block w-full px-2 text-white">
        <Tab.Group>
          <Tab.List className="flex pt-2">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  clsx(
                    "roboto-regular pt-1 xl:text-xl text-[#00adef] font-light leading-5 mr-4 text-lg",
                    "focus:outline-none border-b-[2px] hover:border-[#00adef]",
                    selected ? "border-[#00adef] mr-4" : "border-transparent"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-1">
            <Tab.Panel key={0}>
              <TeamBundleDetails />
            </Tab.Panel>
            <Tab.Panel key={1}>
              <SizeChart />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="md:hidden mx-auto w-full rounded-2xl shadow">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <span className="text-lg roboto-bold text-[#00adef]">
                  Details
                </span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-[#00adef]`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-white">
                <TeamBundleDetails />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <span className="text-lg roboto-bold text-[#00adef]">
                  Size Chart
                </span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-[#00adef]`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-white">
                <SizeChart />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
