import React from "react";
import clsx from "clsx";

export default function VideoButton({ className, children, ...rest }) {
  return (
    <>
      <button
        className={clsx(
          "flex justify-center bg-[#00adef] hover:bg-[#008bb9] px-3 py-2 text-white shadow-md sm:ml-3 sm:w-auto transform hover:translate-y-1 active:translate-y-0 transition-transform duration-200",
          className
        )}
        style={{ cursor: "pointer" }}
        {...rest}
      >
        {children}
      </button>
    </>
  );
}
