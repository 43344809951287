import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";
import AddToCartForm from "./AddToCartForm";
import Button from "../../components/Button";
import Carousel from "../../components/UpdatedCarousel";
import Bands from "./Bands";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import VideoModal from "../../components/VideoModal";

import { isProb3 } from "../../utils/siteOrigin";
const SwiperImages = [
  "bands1.png",
  "bands2.png",
  "bands3.png",
  "bands4.png",
  "bands5.png",
  "bands6.png",
  "bands7.png",
];

export default function Page() {
  const nextPage = isProb3() ? "/check-out" : "/nutrition-products";
  const nextButtonRef = useRef(null);
  const scrollToElement = () => {
    if (nextButtonRef.current) {
      scrollIntoView(nextButtonRef.current, {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-14">
        <div className="relative flex flex-col  mt-5 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem]">
          <h1 className="pb-4 md:pb-0 md:text-7xl text-5xl roboto-medium">
            <span className="text-white">B</span>
            <span className="text-[#00adef] mr-3">3</span>
            <span className="text-white">Bands</span>
          </h1>
          <img
            alt="30Day Icon"
            src="./assets/images/30days-free.png"
            className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[-20px] md:top-[-35px] md:right-[-45px]"
          />
          <Bands />
          <div className="">
            <AddToCartForm onClose={scrollToElement} />
          </div>
        </div>
        <div className="block my-auto mb-6 md:mb-auto items-center">
          <Carousel swiperImages={SwiperImages} />

          <div className="mt-16">
            <VideoModal
              open={isOpenVideo}
              setOpen={setIsOpenVideo}
              videoId="1045362105"
            />
          </div>
        </div>
      </div>
      {/* <div className="flex text-center md:justify-center relative items-center flex-col sm:flex-row justify-between">
        <div className="w-full flex items-center justify-center md:justify-start relative mr-2 pt-4">
          <div className="flex flex-col ">
            <img
              alt="paypal credit"
              src="./assets/images/paypal-credit.png"
              className="w-[100px] md:w-[120px]"
            />
            <span className="text-[10px] font-semibold">
              $0 DOWN 0% INTEREST FOR 6 MONTHS
            </span>
          </div>
          <img
            alt="money-guarantee"
            src="./assets/images/guarantee.jpg"
            className="w-[70px] md:w-[90px] top-6 right-5 md:top-2 ml-2 md:right-2"
          />
        </div>
      </div> */}
      <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
        <NavLink key="b3 bands" to="/bundle">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            <div className="inline-block pr-1 relative top-[3px]">
              <ArrowLeftIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
            {"Back"}
          </Button>
        </NavLink>
        <NavLink
          key="nutrition products"
          to="/nutrition-products"
          ref={nextButtonRef}
        >
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            Next
            <div className="inline-block pl-1 relative top-[3px]">
              <ArrowRightIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
          </Button>
        </NavLink>
      </div>
    </section>
  );
}
