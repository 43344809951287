import { useState, useContext } from "react";

import { toast } from "react-toastify";
import Carousel from "./UpdatedCarousel";
import { Disclosure, Tab } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import Button from "./Button";
import { getCart, saveCart } from "../utils/localstorage";
import PRODUCTS from "../../public/assets/products.json";
import { CartContext } from "../contexts/CartContext";

const NutritionProducts = ({
  nutritionProduct,
  selectProductCount,
  setCurrentStep,
  setSelectedProduct,
}) => {
  const cartContext = useContext(CartContext);
  const [qty, setQty] = useState(1);
  const product = PRODUCTS.find(
    (p) => p.Sku === nutritionProduct?.zeroSku
  );

  const handleAddToCart = (e) => {
    setSelectedProduct((prevSelectedProduct) => {
      const updatedSelectedProduct = prevSelectedProduct + 1;

      // Move logic that depends on the updated state here
      if (qty > 0) {
        toast("Product is added!");
        const cart = getCart();
        const existing = cart.find(
          (p) => p.product.Sku === nutritionProduct?.zeroSku
        );
        if (existing) {
          existing.qty = (Number(existing.qty) || 0) + qty;
        } else {
          cart.push({ product:product, qty });
        }
        cartContext.save(cart);

        if (updatedSelectedProduct === selectProductCount) {
          setCurrentStep(3);
        }
      }

      return updatedSelectedProduct;
    });
  };

  // const confirmSubscribe = (e) => {
  //   setSelectedProduct((prevSelectedProduct) => {
  //     const updatedSelectedProduct = prevSelectedProduct + 1;

  //     // Logic depending on the updated state
  //     const product = PRODUCTS.find(
  //       (p) => p.Sku === nutritionProduct?.subscribeSku
  //     );
  //     if (qty > 0) {
  //       toast("Product is added!");
  //       const cart = getCart();
  //       const productWithZeroPrice = {
  //           ...product,
  //           Price: 0, // Set price to 0 when adding to the cart
  //         };
  //       const existing = cart.find(
  //         (p) => p.product.Sku === nutritionProduct?.subscribeSku
  //       );
  //       if (existing) {
  //         existing.qty = (Number(existing.qty) || 0) + qty;
  //       } else {
  //         cart.push({ product:productWithZeroPrice, qty });
  //       }
  //       cartContext.save(cart);
  //       if (updatedSelectedProduct === selectProductCount) {
  //         setCurrentStep(3);
  //       }
  //     }

  //     return updatedSelectedProduct;
  //   });
  // };

  return (
    <div className="flex flex-col pt-6">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-12">
        <div className="flex relative mt-4 md:mt-0 flex-col bg-[#302b2b] px-6 py-8 rounded-[1rem] h-fit">
          {/* Product Name */}
          <h1 className="text-start pl-1 md:pb-0 md:text-4xl text-3xl roboto-medium">
            <span className="text-white">B</span>
            <span className="text-[#00adef] mr-3">3</span>
            <span className="text-white">{nutritionProduct.productName}</span>
          </h1>

          {/* Details */}

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 mt-4 max-w-[330px] text-left text-sm font-medium text-blue-900 hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span className="text-lg roboto-bold text-[#00adef]">
                    Details
                  </span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-[#00adef]`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-white">
                  <div>
                    <ul>
                      {nutritionProduct.details.map((text, index) => (
                        <li
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? "roboto-black text-3xl pt-5"
                              : "roboto-regular text-xl"
                          }`}
                        >
                          {text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {!nutritionProduct?.comingSoon ? (
            <>
              {/* Add to cart */}
              <div className="flex mt-6 sm:flex-row flex-col px-2">
                <div className="flex items-center font-medium mr-4 gap-4">
                  <div className="!text-2xl md:text-3xl text-[#00adef] my-auto">
                    ${product.Price.toFixed(2)}
                  </div>
                  <Button
                    onClick={handleAddToCart}
                    className="text-lg md:text-xl px-5 py-2 rounded-full roboto-regular-italic min-w-[170px] md:min-w-[215px]"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Add to cart
                  </Button>
                </div>
              </div>
              {/* Subscribe */}
              {/* <div className="flex mt-6 sm:flex-row flex-col px-2">
                <div className="flex items-center font-medium mr-4 gap-4">
                  <div className="!text-2xl text-[#00adef] my-auto">
                    ${nutritionProduct.subscribePrice}
                  </div>
                  <Button
                    onClick={confirmSubscribe}
                    className="text-lg md:text-xl px-5 py-2 rounded-full roboto-regular-italic min-w-[170px] md:min-w-[215px]"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Subscribe + save
                  </Button>
                </div>
              </div> */}
            </>
          ) : (
            <Button
              onClick={() => {}}
              className="text-xl !ml-0 mt-6 md:text-2xl py-2 rounded-full roboto-regular !w-fit"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              {nutritionProduct?.comingSoonText}
            </Button>
          )}
        </div>
        <div className="flex items-start h-[225px] md:h-[280px]">
          <Carousel
            className="!h-[225px] !max-w-[calc(100%-10%)] !mt-0"
            swiperImages={nutritionProduct.images}
            allowExpand
            arrowWidth="150px"
          />
        </div>
      </div>
    </div>
  );
};

export default NutritionProducts;
