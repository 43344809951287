import { useState, useContext, Fragment } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { getCart, saveCart } from "../../utils/localstorage";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";
import { Dialog, Transition } from "@headlessui/react";
export default function AddToCart({ price, sku, preOrder = false }) {
  const notify = () => toast("Product is added!");
  const cartContext = useContext(CartContext);
  const [qty, setQty] = useState(1);
  const product = PRODUCTS.find((p) => p.Sku === sku);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleAddToCart = (e) => {
    if (qty > 0) {
      if (preOrder) {
        setIsPopupOpen(true);
      } else {
        notify();
      }
      const cart = getCart();
      const existing = cart.find((p) => p.product.Sku === sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + qty;
      } else {
        cart.push({ product, qty });
      }
      cartContext.save(cart);
    }
  };

  return (
    <>
      <div className="flex mt-8 sm:flex-row flex-col px-2">
        <div className="flex custom-wrap-center items-center font-medium mr-4 gap-4 md:gap-10">
          <div className="!text-2xl md:!text-4xl text-[#00adef] my-auto">
            ${product.Price}
          </div>
          <Button
            onClick={handleAddToCart}
            className="text-xl md:text-2xl px-5 py-2 rounded-full roboto-regular-italic min-w-[215px]"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            {preOrder ? "Pre Order" : "Add to cart"}
          </Button>
        </div>
        {/* <div className='flex items-center sm:mt-0 mt-4'>
        <span className='text-2xl mr-2'>QTY : </span>
        <input type="number" name="qty" id="qty" defaultValue={1} min={0} value={qty} onChange={(e) => setQty(e.target.value)} className="block w-full flex-1 rounded border md:border-gray-400 border-[#00adef] px-2 py-1.5 w-[65px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="Qty" />
      </div> */}
      </div>

      <Transition appear show={isPopupOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsPopupOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full block max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <span>
                    With your Pre-Order you are agreeing to a future order
                    for $64.99 (+ship/tax). When B3 Boost is ready to ship, we
                    will process and ship your order First!
                  </span>
                  <Button
                    onClick={() => {
                      setIsPopupOpen(false);
                      notify();
                    }}
                    className="!w-fit !mx-auto flex text-base md:text-lg py-2 rounded-full font-light mt-2 justify-center roboto-regular"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Continue
                  </Button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
