import React from "react";

export default function DetailBlock({details}) {
  return (
    <div>
      <ul>
        {details.map((text, index) => (
          <li
            key={index}
            className={`${
              index % 2 === 0
                ? "roboto-black text-3xl pt-3"
                : "roboto-regular text-xl"
            }`}
          >
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
}
