import OpportunityBlock from "./OpportunityBlock";
import Opportunities from "./Opportunities";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";

const founderImages = [
  "founder1.png",
  "founder2.png",
  "founder3.png",
  "founder4.png",
  "founder5.png",
  "founder6.png",
  "founder7.png",
  "founder8.png",
];

export const BusinessOpportunity = () => {
  const businessOpportunityData = [
    {
      title: "Business Bundle",
      saveAmount: "Save $600",
      component: (
        <Opportunities
          details={[
            { text: "Exclusive Founder Benefits" },
            { text: "3 Sets of B3 Bands (12 Bands + 3 Pumps)" },
            {
              text: "3 Duffel Bags, 3 Exercise Tubing Sets",
            },
            {
              text: "3 B3 Nutrition Products",
            },
            {
              text: "Personalized B3 Website to Share and Earn",
            },
            {
              text: "5 B3 Booklets and 5 B3 Brochures for sharing",
            },
            {
              text: " B3 Share App",
            },
            { text: "B3 U Fitness App Free First 30 Days" },
            {text: " BFR Diet Course" },
            { text: "B3 Member for Commissions" },
            { text: "BFR Certification Course" },
            { text: "Personal Mentoring from B3 President" },
            { text: "30 Day Money Back Guarantee" },
            { text: "12 Month Warranty" },
          ]}
          subHeading="A Complete Turn-key BFR Business"
        />
      ),
      sku: "RCFounder",
      images: founderImages,
      videoId: "1044755162",
    },
    // {
    //   title: "Professional Pack for Doctors/Clinics - Save $400",
    //   component: (
    //     <Opportunities
    //       details={[
    //         { text: "All 5 Bands sizes to fit all patients (10 Bands)" },
    //         { text: "2 pumps, mesh carry bag" },
    //         {
    //           text: "BFR certification course + exam",
    //         },
    //       ]}
    //       subHeading="A full BFR kit for your office"
    //     />
    //   ),
    //   sku: "founderPack",
    //   images: founderPackImages,
    // },
    // {
    //   title: "Comprehensive Clinic/Team Pack - Save $1700",
    //   component: (
    //     <Opportunities
    //       details={[
    //         { text: "10 sets of B3 Bands (40 Bands)" },
    //         { text: "10 pumps, 10 duffel bags, 10 sets of exercise tubing" },
    //         {
    //           text: "B3 Share’ and ‘B3 U’ mobile apps",
    //         },
    //         {
    //           text: "BFR certification course + exam for 4 people",
    //         },
    //         { text: "Private 2-hour online training with Dr Mike" },
    //       ]}
    //       subHeading="Bands, education and business tools for your clinic or team"
    //     />
    //   ),
    //   sku: "clinicTeamPack",
    //   images: clinicTeamPackImages,
    // },
  ];

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <>
        <h1 className="pb-4 pl-1 md:py-4 text-3xl md:text-5xl  roboto-medium max-w-[800px]">
          Founder Business Package
        </h1>
        <div className="flex flex-col gap-8 pl-1">
          {businessOpportunityData.map(
            ({
              title,
              duration,
              component,
              images,
              sku,
              saveAmount,
              videoId,
            }) => (
              <>
                <OpportunityBlock
                  key={sku}
                  title={title}
                  saveAmount={saveAmount}
                  duration={duration}
                  component={component}
                  images={images}
                  sku={sku}
                  videoId={videoId}
                />
              </>
            )
          )}
        </div>
        <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
          <NavLink key="b3 bands" to="/become-a-member">
            <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
              <div className="inline-block pr-1 relative top-[3px]">
                <ArrowLeftIcon
                  fill="white"
                  className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                  aria-hidden="true"
                  color="white"
                />
              </div>
              {"Back"}
            </Button>
          </NavLink>
          <NavLink key="nutrition products" to="/bfr-courses">
            <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
              Next
              <div className="inline-block pl-1 relative top-[3px]">
                <ArrowRightIcon
                  fill="white"
                  className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                  aria-hidden="true"
                  color="white"
                />
              </div>
            </Button>
          </NavLink>
        </div>
      </>
    </section>
  );
};
