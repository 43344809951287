import React from "react";

const VimeoPlayer = ({ videoId, width = "100%", height = "100%" }) => {
  const iframeSrc = `https://player.vimeo.com/video/${videoId}?badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479`;

  return (
    <div className="pt-[60.25%] pb-[4%] md:pb-2 relative">
      <iframe
        src={iframeSrc}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ position: "absolute", top: 0, left: 0, width, height }}
        title="Vimeo Video Player"
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;
