import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { Listbox, Transition, Dialog } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { getCart, saveCart } from "../../utils/localstorage";
import Button from "../../components/Button";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import SizeChart from "../../components/SizeChart";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import Stepper from "../../components/Stepper";
import { nutritionProductsData } from "../nutrition-products/productData";
import B3Member from "../../components/B3Member";

import SiteSelection from "../../components/SiteSelection";
import NutritionProducts from "../../components/NutritionProducts";

const armBands = [
  {
    Sku: "Bands1",
    ProductName: "B3 Band Size 1",
    Description: "B3 Band Size 1",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands2",
    ProductName: "B3 Band Size 2",
    Description: "B3 Band Size 2",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const legBands = [
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands4",
    ProductName: "B3 Band Size 4",
    Description: "B3 Band Size 4",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands5",
    ProductName: "B3 Band Size 5",
    Description: "B3 Band Size 5",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const getProductsBySKUs = (skus) =>
  skus
    .map((sku) => PRODUCTS.find((product) => product.Sku === sku))
    .filter(Boolean);

const additionalItems = getProductsBySKUs(["Pump1", "ExerTub1", "DuffleBag1"]);
const additionalBookItems = getProductsBySKUs(["B3Book5Fr", "B3Bro5" , "BFRDietTD"]);
const additionalSize5 = PRODUCTS.find((p) => p.Sku === "RCSize5");
const memberKit = PRODUCTS.find((p) => p.Sku === "MemKitTD");
const bfrCourse = PRODUCTS.find((p) => p.Sku === "BBCCTD");

export default function AddToCartForm({ sku, handleAddToCart }) {
  const cartContext = useContext(CartContext);
  let navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const [prefix, setPrefix] = useState("");
  const [available, setAvailable] = useState("");
  const [selected, setSelected] = useState(PRODUCTS.find((p) => p.Sku === sku));
  const [armSizes, setArmSizes] = useState(Array(3).fill(armBands[0]));
  const [currentStep, setCurrentStep] = useState(1);
  const contentRef = useRef(null);

  const [legSizes, setLegSizes] = useState(Array(3).fill(legBands[0]));

  const [isOpen, setIsOpen] = useState(false);
  const product = PRODUCTS.find((p) => p.Sku === sku);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const cart = getCart();

  const addToCart = (item, quantity = 1, checkExisting = true) => {
    if (!item) return;
    if (checkExisting) {
      const exists = cart.some((p) => p.product.Sku === item.Sku);
      if (exists) return;
    }
    cart.push({ product: item, qty: quantity });
  };

  const addProduct = () => {
    notify();
    // Add ProdctType - SET
    const existing = cart.find((p) => p.product.Sku === selected.Sku);
    if (existing) {
      navigate("/check-out");
      return;
    } else {
      cart.push({ product: selected, qty: 1 });
    }

    // Add individual items
    armSizes.concat(legSizes).forEach((product) => {
      const existing = cart.find((p) => p.product.Sku === product.Sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + 2;
      } else {
        cart.push({ product, qty: 2 });
      }
      if (product.Sku === "Bands5") {
        const existingAdditional = cart.find(
          (p) => p.product.Sku === "RCSize5"
        );
        if (existingAdditional) {
          existingAdditional.qty = (Number(existingAdditional.qty) || 0) + 1;
        } else {
          cart.push({ product: additionalSize5, qty: 1 });
        }
      }
    });

    // zero price sku
    additionalItems.forEach((item) => addToCart(item, 3, false));
    addToCart(memberKit);
    addToCart(bfrCourse);
    additionalBookItems.forEach((item) => addToCart(item));

    cartContext.save(cart);
    setCurrentStep(2);
    handleAddToCart();
  };

  const checkAvailability = () => {
    if (prefix.length > 25) {
      return;
     }
    fetch(
      `${process.env.REACT_APP_API_URI}/api/Member/replicatedwebsite/${prefix}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.replicatedNameOnWebSite) {
          setAvailable("Taken");
        } else {
          localStorage.setItem("memberWebsitePrefix", prefix);
          setAvailable("Available");
        }
      });
  };
  const [selectedProduct, setSelectedProduct] = useState(0);

  const selectProductCount = 3;
  const onContinue = () => {
    const cart = getCart();
    if (prefix) {
      if (available === "Available") {
        localStorage.setItem("currentRoute", "detail");
        saveCart(cart);
        setCurrentStep(4);
        // navigate("/check-out");
      } else {
        toast.error("Please check Website availability");
      }
    } else {
      toast.error("Please select Website URL");
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col">
      <div className="md:flex flex-row items-center gap-2">
        <div className="flex gap-4 pt-4 md:py-2">
          <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
            ${product.Price}
          </div>
          <Button
            type="button"
            onClick={openModal}
            className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            ADD TO CART
          </Button>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            if (currentStep === 1) {
              closeModal();
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  ref={contentRef}
                  className="lg:max-w-5xl max-w-3xl transform rounded-2xl bg-white px-6 md:px-10 pb-10 text-left align-middle shadow-xl transition-all sm:max-h-auto max-h-[700px] overflow-auto"
                >
                  <Stepper
                    currentStep={currentStep}
                    steps={[
                      <div key="step1">
                        {" "}
                        <>
                          <Dialog.Title
                            as="h3"
                            className="text-xl pt-12 md:text-2xl font-medium leading-6 text-[#00adef]"
                          >
                            Size Selection
                          </Dialog.Title>
                          <div>
                            <div className="flex lg:flex-row flex-col items-start justify-center">
                              <div className="flex flex-col overflow-x-visible mb-4 md:p-4 md:min-h-[400px] justify-start">
                                {Array.from(Array(3), (e, i) => {
                                  return (
                                    <div key={i} className="mt-4">
                                      <div className="text-2xl capitalize text-[#00adef] mb-3">
                                        Set #{i + 1}
                                      </div>
                                      <div className="flex gap-2 flex-col sm:flex-row">
                                        <div className="flex flex-col">
                                          <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                            Arm Sizes
                                          </div>
                                          <Listbox
                                            value={armSizes[i]}
                                            onChange={(value) =>
                                              setArmSizes((prev) => {
                                                const newValue = [...prev];
                                                newValue[i] = value;
                                                return newValue;
                                              })
                                            }
                                          >
                                            <div className="relative mt-1">
                                              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                                <span className="block truncate">
                                                  {armSizes[i].ProductName}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                  <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400 sm:text-2xl"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              </Listbox.Button>
                                              <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                                  {armBands.map((band, idx) => (
                                                    <Listbox.Option
                                                      key={band.Sku}
                                                      className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                          active
                                                            ? "bg-amber-100 text-amber-900"
                                                            : "text-gray-900"
                                                        }`
                                                      }
                                                      value={band}
                                                    >
                                                      <div className="flex flex-col text-base font-light">
                                                        <div>
                                                          <span
                                                            className={`block truncate ${
                                                              armSizes[i]
                                                                .Sku ===
                                                              band.Sku
                                                                ? "font-normal"
                                                                : "font-light"
                                                            }`}
                                                          >
                                                            {band.ProductName}
                                                          </span>
                                                          {armSizes[i].Sku ===
                                                          band.Sku ? (
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                              <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </Listbox.Option>
                                                  ))}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </Listbox>
                                        </div>
                                        <div className="flex flex-col sm:mt-0 mt-3">
                                          <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                            Leg Sizes
                                          </div>
                                          <Listbox
                                            value={legSizes[i]}
                                            onChange={(value) =>
                                              setLegSizes((prev) => {
                                                const newValue = [...prev];
                                                newValue[i] = value;
                                                return newValue;
                                              })
                                            }
                                          >
                                            <div className="relative mt-1">
                                              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                                <span className="block truncate">
                                                  {legSizes[i].ProductName}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                  <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400 sm:text-2xl"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              </Listbox.Button>
                                              <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                                  {legBands.map((band) => (
                                                    <Listbox.Option
                                                      key={band.Sku}
                                                      className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                          active
                                                            ? "bg-amber-100 text-amber-900"
                                                            : "text-gray-900"
                                                        }`
                                                      }
                                                      value={band}
                                                    >
                                                      <div className="flex flex-col text-base font-light">
                                                        <div>
                                                          <span
                                                            className={`block truncate ${
                                                              legSizes[i]
                                                                .Sku ===
                                                              band.Sku
                                                                ? "font-normal"
                                                                : "font-light"
                                                            }`}
                                                          >
                                                            {band.ProductName}
                                                          </span>
                                                          {legSizes[i].Sku ===
                                                          band.Sku ? (
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                              <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : null}
                                                        </div>
                                                        {band.Sku ===
                                                          "Bands5" && (
                                                          <div className="text-[#00adef] text-sm">
                                                            Additional $30
                                                          </div>
                                                        )}
                                                      </div>
                                                    </Listbox.Option>
                                                  ))}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </Listbox>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="hidden sm:block min-w-[100px] flex justify-center ">
                                <SizeChart />
                              </div>

                              <div className="sm:hidden mx-auto w-full rounded-2xl shadow bg-white max-w-[250px]">
                                <Disclosure as="div" className="mt-2">
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex w-full justify-between cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                        <span className="text-md font-500 capitalize mb-1 md:mb-2">
                                          Size Chart
                                        </span>
                                        <ChevronUpIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5`}
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <SizeChart />
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                            </div>
                            <div className="flex justify-center mt-3 w-full">
                              <Button
                                type="button"
                                onClick={addProduct}
                                className="text-base md:text-base md:text-lg px-5 py-2 rounded-full font-light"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                              >
                                ADD TO CART
                              </Button>
                            </div>
                          </div>
                        </>
                      </div>,
                      <div key="step2">
                        <>
                          <div className="block md:flex justify-between sticky pb-2 top-0 bg-white z-10">
                            <h1 className="text-2xl pt-12 md:text-4xl font-medium text-[#00adef]">
                              <span>
                                Select {selectProductCount} B3 Nutrition
                                Products
                              </span>
                            </h1>

                            <div className="flex space-x-2 items-center pt-4 pr-6 md:pt-12">
                              {Array(selectProductCount)
                                .fill(0)
                                .map((step, index) => (
                                  <div
                                    key={step}
                                    className={`h-2 w-10 rounded ${
                                      selectedProduct > index
                                        ? "bg-[#00adef]"
                                        : "bg-gray-300"
                                    }`}
                                  ></div>
                                ))}
                              <h1 className="text-xl font-medium text-[#00adef]">
                                <span>
                                  {selectedProduct}/{selectProductCount}
                                </span>
                              </h1>
                            </div>
                          </div>
                          {nutritionProductsData.map((nutritionProduct) => {
                            return (
                              <NutritionProducts
                                nutritionProduct={nutritionProduct}
                                selectProductCount={selectProductCount}
                                setCurrentStep={setCurrentStep}
                                setSelectedProduct={setSelectedProduct}
                              />
                            );
                          })}
                        </>
                      </div>,
                      <div key="step3">
                        {" "}
                        <SiteSelection
                          checkAvailability={checkAvailability}
                          onContinue={onContinue}
                          setPrefix={setPrefix}
                          available={available}
                          prefix={prefix}
                        />
                      </div>,
                      <div key="step4">
                        <B3Member />
                      </div>,
                    ]}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
