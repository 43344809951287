import React from "react";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";

const Stepper = ({ currentStep, steps }) => {
  const StepContent = ({ currentStep }) => {
    const stepData = steps[currentStep - 1];
    return stepData ? <>{stepData}</> : null;
  };

  return (
    <div className="flex flex-col">
      <div className="relative pt-10 flex justify-between items-center w-full sticky z-10 top-0 bg-white">
        {steps?.map((_, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div
                className={`h-1 flex-1 ${
                  currentStep > index ? "bg-[#00adef]" : "bg-gray-300"
                }`}
              ></div>
            )}

            <div
              className={`w-10 h-10 flex items-center justify-center font-bold rounded-full z-100 ${
                currentStep > index + 1
                  ? "bg-[#00adef] text-white"
                  : currentStep === index + 1
                  ? "bg-[#00adef] text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > index + 1 ? (
                <CheckIcon
                  className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                  aria-hidden="true"
                  color="white"
                />
              ) : (
                index + 1
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div>
        <StepContent currentStep={currentStep} />
      </div>
    </div>
  );
};

export default Stepper;
