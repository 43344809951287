import { useContext } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { getCart } from "../../utils/localstorage";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";

export default function AddToCart({ price, sku , setShowError }) {
  const notify = () => toast("Course is added!");
  const cartContext = useContext(CartContext);
  const product = PRODUCTS.find((p) => p.Sku === sku);

  const handleAddToCart = (e) => {
    const cart = getCart();
    const existing = cart.find((p) => p.product.Sku === sku);
    if (existing) {
      toast("Course is already added!");
    } else {
      cart.push({ product, qty: 1 });
      notify();
    }
    setShowError(false)
    cartContext.save(cart);
  };

  return (
    <div className="md:flex flex-row items-center gap-4 py-2 pl-2 md:pl-0">
      <div className="flex items-center font-medium mr-4 gap-4">
        <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
          ${product.Price}
        </div>
        <Button
          onClick={handleAddToCart}
          className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          ADD TO CART
        </Button>
      </div>
    </div>
  );
}
