export default function DoubleBundleDetails() {
  return (
    <div className="roboto-regular">
      <ul className="list-disc pl-4 py-3">
        <li className="py-1 text-xl">B3 Bands Complete Set x 2</li>
        <li className="py-1 text-xl">B3 Duffel Bag & Exercise Tubing x 2</li>
        <li className="py-1 text-xl">
          B3 Nutrition Product Of Your Choice x 2
        </li>
        <li className="py-1 text-xl">B3 U Fitness App – Free First 30 Days</li>
        <li className="py-1 text-xl">BFR Diet Course</li>
        <li className="py-1 text-xl">B3 Member for Commissions</li>
        <li className="py-1 text-xl">
          Personalized Website for Sharing & Earning
        </li>
        <li className="py-1 text-xl">
          5 B3 Booklets and 5 B3 Brochures for sharing
        </li>
        <li className="py-1 text-xl">
          B3 Share App
        </li>
        <li className="py-1 text-xl">30 Day Money Back Guarantee</li>
        <li className="py-1 text-xl">12 Month Warranty</li>
      </ul>
    </div>
  );
}
