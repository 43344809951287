import { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Disclosure, Tab } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import Button from "../../components/Button";
import Personal from "./Personal";
import { getCart, saveCart } from "../../utils/localstorage";
import SiteSelection from "./SiteSelection";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/UpdatedCarousel";
import PRODUCTS from "../../../public/assets/products.json";
import VideoModal from "../../components/VideoModal";
import { Dialog } from "@headlessui/react";
// import Professional from "./Professional";
// import ShareAndEarn from "./ShareAndEarn";

const categories = ["Details"];

export default function Member() {
  const cartContext = useContext(CartContext);
  const navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const memberProduct = {
    ...PRODUCTS.find((p) => p.Sku === "B3SubTD"),
  };

  const [isOpen, setOpen] = useState(false);

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [isMobileVideo, setIsMobileVideo] = useState(false);

  const bundleMemberProduct = {
    ...PRODUCTS.find((p) => p.Sku === "B3SubTD"),
  };
  const fiveBooks = {
    Sku: "B3Book5",
    ProductName: "5 Books",
    Price: 29.99,
    Image: "books.png",
  };

  const thirtyBooks = {
    Sku: "B3Book30",
    ProductName: "30 Books",
    Price: 164.99,
    Image: "books.png",
  };
  const ninetyBooks = {
    Sku: "B3Book90",
    ProductName: "90 Books",
    Price: 449.99,
    Image: "books.png",
  };
  const cart = getCart();
  const [page, setPage] = useState("detail");
  const [showBook, setShowBook] = useState(
    cart.find((p) => p.product.Sku === memberProduct.Sku)
  );
  const [prefix, setPrefix] = useState("");
  const [available, setAvailable] = useState("");
  const toastShownRef = useRef(false);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));

    const isMemKitTDPresent = cart?.some(
      (item) => item.product?.Sku === bundleMemberProduct.Sku
    );

    if (isMemKitTDPresent && !toastShownRef.current) {
      toastShownRef.current = true;
      navigate("/check-out");
      cartContext?.setShowError(true);
    }
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const addMemKit = () => {
    // setPage("website");
    // localStorage.setItem("currentRoute", "website");
    const existing = cart.find((p) => p.product.Sku === memberProduct.Sku);
    setShowBook(true);
    if (existing) {
      return;
    } else {
      memberProduct.Price = 0.01;
      cart.push({ product: memberProduct, qty: 1 });
      notify();
    }
    navigate("/check-out");
    cartContext.save(cart);
  };

  const handleAddToCart = (product) => {
    const existing = cart.find((p) => p.product.Sku === product.Sku);
    if (existing) {
      return;
    } else {
      cart.push({ product: product, qty: 1 });
      notify();
    }
    saveCart(cart);
  };

  const checkAvailability = () => {
    if (prefix.length > 25) {
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_URI}/api/Member/replicatedwebsite/${prefix}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.replicatedNameOnWebSite) {
          setAvailable("Taken");
        } else {
          localStorage.setItem("memberWebsitePrefix", prefix);
          setAvailable("Available");
        }
      });
  };

  // const onContinue = () => {
  //   const cart = getCart();
  //   if (prefix) {
  //     if (available === "Available") {
  //       setPage("detail");
  //       localStorage.setItem("currentRoute", "detail");
  //       const existing = cart.find((p) => p.product.Sku === memberProduct.Sku);
  //       if (existing) {
  //         navigate("/check-out");
  //       } else {
  //         cart.push({ product: PRODUCTS.find((p) => p.Sku === memberProduct.Sku), qty: 1 });
  //         notify();
  //       }
  //       saveCart(cart);
  //       navigate("/check-out");
  //     } else {
  //       toast.error("Please check Website availability");
  //     }
  //   } else {
  //     toast.error("Please select Website URL");
  //   }
  // };

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      {page === "detail" && (
        <>
          <div className="flex flex-col gap-16 ">
            <div className="flex flex-col">
              <div className="block my-auto mb-6 md:mb-auto pt-0 items-center justify-center block lg:hidden mb-4">
                <Carousel swiperImages={["subscription-image.png"]} />
                <div className="mt-8">
                  <VideoModal
                    open={isMobileVideo}
                    setOpen={setIsMobileVideo}
                    videoId="1045383546"
                  />
                </div>
              </div>
              <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-14">
                <div className="flex flex-col mb-2 mt-2 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem]">
                  <h1 className="pb-4 md:pb-0 md:text-7xl text-4xl roboto-medium">
                    <span className="text-white">
                      B<span className="text-[#00adef] mr-2">3</span>U Fitness
                      App
                    </span>
                    <br className="sm:hidden block" />
                  </h1>
                  <div className="hidden md:block w-full px-2">
                    <Tab.Group>
                      <Tab.List className="flex pt-2">
                        {categories.map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              clsx(
                                "roboto-regular pt-1 xl:text-xl text-[#00adef] font-light leading-5 mr-4 text-lg",
                                "focus:outline-none border-b-[2px] hover:border-[#00adef]",
                                selected
                                  ? "border-[#00adef] mr-4"
                                  : "border-transparent"
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="mt-1">
                        <Tab.Panel key={0}>
                          <Personal />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>

                  <div className="md:hidden mx-auto w-full rounded-2xl shadow bg-[#302b2b] p-2 px-0">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75">
                            <span className="text-lg font-bold text-[#00adef]">
                              Details
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Personal />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className="flex gap-4 py-4 justify-center md:justify-start">
                    <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
                      {/* ${memberProduct.Price} monthly */}
                      $0 Today
                    </div>
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      ADD TO CART
                    </Button>
                  </div>
                </div>
                <div className="block my-auto mb-6 md:mb-auto hidden lg:block pt-0 items-center justify-center">
                  <Carousel swiperImages={["subscription-image.png"]} />
                  <div className="mt-8">
                    <VideoModal
                      open={isOpenVideo}
                      setOpen={setIsOpenVideo}
                      videoId="1045383546"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
            <NavLink key="b3 bands" to="/nutrition-products">
              <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                <div className="inline-block pr-1 relative top-[3px]">
                  <ArrowLeftIcon
                    fill="white"
                    className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                    aria-hidden="true"
                    color="white"
                  />
                </div>
                {"Back"}
              </Button>
            </NavLink>
            <NavLink key="nutrition products" to="/business-opportunity">
              <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                Next
                <div className="inline-block pl-1 relative top-[3px]">
                  <ArrowRightIcon
                    fill="white"
                    className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                    aria-hidden="true"
                    color="white"
                  />
                </div>
              </Button>
            </NavLink>
          </div>

          <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="max-w-xl w-full rounded bg-white p-6">
                <Dialog.Title></Dialog.Title>
                <Dialog.Description className="mt-2 ">
                  <div className="roboto-regular">
                    <p className="text-lg">
                      If you already have an account with B
                      <span className="text-[#00adef]">3</span> Sciences do not
                      place this order. Simply email us at
                      <a
                        href="mailto:support@b3sciences.com"
                        className="text-[#00adef] ml-1"
                      >
                        support@b3sciences.com
                      </a>{" "}
                      and request your 30 days of free access.
                    </p>
                  </div>
                </Dialog.Description>
                <p className="text-2xl text-center pt-2">
                  Continue with the B<span className="text-[#00adef]">3</span> U
                  Order
                </p>
                <div className="text-center pt-2 flex gap-1">
                  <Button
                    onClick={addMemKit}
                    className="mx-auto !w-full text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={onClose}
                    className="mx-auto !w-full text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    No
                  </Button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </>
      )}
      {/* {page === "website" && (
        <>
          <SiteSelection
            checkAvailability={checkAvailability}
            onContinue={onContinue}
            setPrefix={setPrefix}
            available={available}
            prefix={prefix}
          />
        </>
      )} */}
    </section>
  );
}
