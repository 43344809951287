import React, { Fragment, useState } from "react";
import Carousel from "../../components/UpdatedCarousel";
import AddToCart from "./AddToCart";
import SelectionAddToCart from "./SelectionAddToCart";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button";

export const Details = ({ product }) => {
  const [isOpen, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <div className="flex flex-col">
        <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-8 md:gap-12">
          <div className="flex flex-col !my-auto mt-2 md:mt-5 h-fit bg-[#302b2b] p-6 rounded-[1rem]">
            <h1 className=" md:text-5xl text-3xl roboto-medium">
              <span className="text-white">{product.productName}</span>
            </h1>
            <div className="roboto-regular pt-4 pb-3">
              <ul className="list-disc pl-4 pb-1">
                {product.details?.map((item, index) => (
                  <li key={index} className="py-1 text-xl text-white">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {product.sizeSelection ? (
              <SelectionAddToCart
                skuConfigurations={product.skuConfigurations}
                setOpen={setOpen}
              />
            ) : (
              <AddToCart sku={product.addToCartSku} setOpen={setOpen} />
            )}
          </div>
          <div className="flex items-center">
            <Carousel
              className="!h-auto !m-0"
              swiperImages={product.images}
              allowExpand
            />
          </div>
        </div>
      </div>

      <Dialog open={isOpen} onClose={onClose} className="relative z-50">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-lg w-full rounded bg-white p-6">
            <Dialog.Title>
              <h1 className="pb-0 text-2xl roboto-medium">Does Not Include</h1>
            </Dialog.Title>
            <Dialog.Description className="mt-2 ">
              <div className="roboto-regular">
                <ul className="list-disc pl-2 ml-4">
                  <li className="text-lg">30 Day Coaching</li>
                  <li className="text-lg">
                    B<span className="text-[#00adef]">3</span> U App
                  </li>
                  <li className="text-lg">Exercise Tubing</li>
                  <li className="text-lg">Duffle Bag</li>
                </ul>
                <div className="text-xl text-black roboto-regular-italic mt-2">
                  To include these, choose a complete Bands set
                </div>
              </div>
            </Dialog.Description>
            <div className="text-center pt-2">
              <Button
                onClick={onClose}
                className="mx-auto !w-full text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                CONTINUE SHOPPING
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Fragment>
  );
};
