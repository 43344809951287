import React, { useState, Fragment } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Pagination, EffectCube, Keyboard } from "swiper";
import { Dialog, Transition } from "@headlessui/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";

export default function Carousel(props) {
  const { swiperImages, allowExpand = false , arrowWidth } = props;
  return (
    <Swiper
      modules={[Navigation, Pagination, EffectCube, Keyboard]}
      pagination={{
        clickable: true,
      }}
      navigation={{
        nextEl: ".swiper-button-next-custom",
        prevEl: ".swiper-button-prev-custom",
      }}
      grabCursor={true}
      className={`h-[300px] max-w-[calc(100%-3%)] lg:h-[480px] rounded-[1rem] lg:mb-0 ${
        props.className ?? ""
      }`}
      loop={true}
      cubeEffect={{
        shadow: true,
        slideShadows: false,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      effect={"cube"}
      keyboard={{
        enabled: true,
      }}
    >
      {swiperImages.map((img) => (
        <SwiperSlide key={img} className="rounded-[3rem]">
          <CarouselContent img={img} allowExpand={allowExpand} />
        </SwiperSlide>
      ))}
      {/* Custom Navigation Buttons */}
      <div className="flex justify-center">
        <button className="swiper-button-prev-custom transform text-black p-3 z-10 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 50"
            className={`w-[150px] md:w-[${arrowWidth || "200px"}]`}
            height="50"
          >
            <line
              x1="40"
              y1="25"
              x2="220"
              y2="25"
              stroke="black"
              stroke-width="4"
            />
            <polygon points="35,25 50,20 50,30" fill="black" />
          </svg>
        </button>
        <button className="swiper-button-next-custom text-black p-3 z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 50"
            // width="200"
            height="50"
            className={`w-[150px] md:w-[${arrowWidth || "200px"}]`}
          >
            <line
              x1="10"
              y1="25"
              x2="160"
              y2="25"
              stroke="black"
              stroke-width="4"
            />
            <polygon points="165,25 150,20 150,30" fill="black" />
          </svg>
        </button>
      </div>
    </Swiper>
  );
}

function CarouselContent({ img, allowExpand }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div className="flex justify-center h-full">
      <Transition appear show={isPopupOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsPopupOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <img
                    alt={img}
                    src={`./assets/images/${img}`}
                    className="w-full h-full mt-2"
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <img
        alt={img}
        src={`./assets/images/${img}`}
        className="rounded-[3rem] object-[50%]"
        onClick={() => allowExpand && setIsPopupOpen(true)}
      />
    </div>
  );
}
