export const miscBandsProductsData = [
  {
    productName: "Pair Arm Bands + Pump",
    images: ["pair-arm-main.png"],
    details: ["B3 Band Single Pair (2 Bands) + Pump"],
    skuConfigurations: [
      {
        Sku: "BandPrSet1",
        Size: "B3 Band Size 1",
      },
      {
        Sku: "BandPrSet2",
        Size: "B3 Band Size 2",
      },
    ],
    sizeSelection: true,
  },
  {
    productName: "Pair Leg Bands + Pump",
    images: ["pair-leg-main.png"],
    details: ["B3 Band Single Pair (2 Bands) + Pump"],
    skuConfigurations: [
      {
        Sku: "BandPrSet3",
        Size: "B3 Band Size 3",
      },
      {
        Sku: "BandPrSet4",
        Size: "B3 Band Size 4",
      },
      {
        Sku: "BandPrSet5",
        Size: "B3 Band Size 5",
        Additional: 30,
      },
    ],
    sizeSelection: true,
  },
  {
    productName: "Single Arm Band",
    images: ["single-arm-main.png"],
    details: ["B3 Band Single Arm"],
    skuConfigurations: [
      {
        Sku: "1Band",
        Size: "B3 Band Size 1",
      },
      {
        Sku: "2Band",
        Size: "B3 Band Size 2",
      },
    ],
    sizeSelection: true,
  },
  {
    productName: "Single Leg Band",
    images: ["single-leg-main.png"],
    details: ["B3 Band Single Leg"],
    skuConfigurations: [
      {
        Sku: "3Band",
        Size: "B3 Band Size 3",
      },
      {
        Sku: "4Band",
        Size: "B3 Band Size 4",
      },
      {
        Sku: "5Band",
        Size: "B3 Band Size 5",
        Additional: 30,
      },
    ],
    sizeSelection: true,
  },
  {
    productName: "Pump",
    images: ["pump-main.png"],
    details: ["B3 Bands Pump"],
    addToCartSku: "Pump",
    sizeSelection: false,
  },
];
