export const nutritionProductsData = [
  {
    productName: "Start",
    images: ["start1.png", "start2.png"],
    details: [
      "Targeted Collagen Peptides",
      "Support skin, hair, nails & joint health",
      "Antiaging Effect with B3 Bands",
      "Boost connective tissue",
      "Fuel Your Day",
      "Coffee bean caffeine ~ 1 cup of coffee",
      "30 Servings & Easy to Use",
      "Mix with water or blend into a smoothie",
    ],
    bottomIcon: [
      {
        src: "./assets/images/nf1.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft6.png",
        className: "w-[27%] h-[70%] mt-4 mt-[18px] my-auto",
      },
      {
        src: "./assets/images/nf2.png",
        className: "mt-[5px] w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft8.png",
        className: "w-[27%] h-[60%] mt-[17px] md:mt-[23px] my-auto",
      },
    ],
    addToCartSku: "RCStart",
    subscribeSku: "Start1",
    zeroSku: "StartTD",
    factsImage: "B3-Start-Supp-Facts.png",
    flavorImage: "startFlavor.png",
    videoId: "1044352614",
  },
  {
    productName: "Start Caffeine Free",
    images: ["start-caffeine1.png", "start-caffeine2.png"],
    details: [
      "Targeted Collagen Peptides",
      "Support skin, hair, nails & joint health",
      "Antiaging Effect with B3 Bands",
      "Boost connective tissue",
      "30 Servings & Easy to Use",
      "Mix with water or blend into a smoothie",
    ],
    bottomIcon: [
      {
        src: "./assets/images/nf1.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft6.png",
        className: "w-[27%] h-[70%] mt-4 mt-[18px] my-auto",
      },
      {
        src: "./assets/images/nf2.png",
        className: "mt-[5px] w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft8.png",
        className: "w-[27%] h-[60%] mt-[17px] md:mt-[23px] my-auto",
      },
    ],
    addToCartSku: "RCStartFR",
    subscribeSku: "StartFR1",
    zeroSku: "StartFTD",
    factsImage: "Start-Free-Supp-Facts.png",
    flavorImage: "startFlavor.png",
    videoId: "1044352614",
  },
  {
    productName: "Satisfy",
    images: ["satisfy1.png", "satisfy2.png"],
    details: [
      "11 Unique Ingredients",
      "For hunger control, metabolism, & weight management",
      "Pair with B3 Bands",
      "Boost results by stimulating HGH",
      "30 Servings & Easy to Use",
      "Mix with water",
    ],
    bottomIcon: [
      {
        src: "./assets/images/nf1.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nf5.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft6.png",
        className: "w-[27%] h-[70%] mt-4 mt-[18px] my-auto",
      },
      {
        src: "./assets/images/nf2.png",
        className: "mt-[5px] w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft8.png",
        className: "w-[25%] h-[60%] mt-[17px] md:mt-[23px] my-auto",
      },
    ],
    addToCartSku: "RCSatisfy",
    subscribeSku: "Satisfy1",
    zeroSku: "SatisfyTD",
    factsImage: "satisfyFact.jpeg",
    flavorImage: "satisfyFlavor.png",
    videoId: "1044730406",
  },
  {
    productName: "Meal",
    images: ["meal1.png", "meal2.png", "meal3.png"],
    details: [
      "Protein for the Whole Family",
      "Balanced nutrition comparable to a healthy meal",
      "Packed With Nutrients",
      "Plant-based protein, veggies, essential fats & minerals",
      "30 Servings & Easy to Use",
      "Mix with water or blend into a shake",
    ],
    bottomIcon: [
      {
        src: "./assets/images/nf3.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft6.png",
        className: "w-[27%] h-[70%] mt-4 mt-[18px] my-auto",
      },
      // {
      //   src: "./assets/images/nf2.png",
      //   className: "mt-[5px] w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      // },
      // {
      //   src: "./assets/images/nft8.png",
      //   className: "w-[27%] h-[60%] mt-[17px] md:mt-[23px] my-auto",
      // },
    ],
    addToCartSku: "RCMeal",
    subscribeSku: "Meal1",
    zeroSku: "MealTD",
    factsImage: "Meal-Facts.png",
    flavorImage: "mealFlavor.png",
    videoId: "415197579",
  },
  {
    productName: "Boost",
    images: ["coming-boost3.png", "coming-boost5.png"],
    details: [
      "Optimize Your Day",
      "Boost energy, cognition & workout performance",
      "Packed With Power",
      "15 unique, potent nutrients to fuel your body",
      "30 Servings & Easy to Use",
      "Mix with water or blend into a shake",
    ],
    bottomIcon: [
      {
        src: "./assets/images/nf1.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nf5.png",
        className: "my-auto w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft6.png",
        className: "w-[27%] h-[70%] mt-4 mt-[18px] my-auto",
      },
      {
        src: "./assets/images/nf2.png",
        className: "mt-[5px] w-[60px] h-[60px] md:w-[90px] md:h-[90px]",
      },
      {
        src: "./assets/images/nft8.png",
        className: "w-[25%] h-[60%] mt-[17px] md:mt-[23px] my-auto",
      },
    ],
    addToCartSku: "RCBoost",
    subscribeSku: "Boost1",
    zeroSku: "BoostTD",
    factsImage: "boostFact.png",
    flavorImage: "alertFlavor.png",
    comingSoon: true,
    comingSoonText: "COMING IN FEBRUARY 2025",
    videoId: "",
    preOrder : true,
    preOrderSku : "BoostTD",
    preOrderImage : ["boost-pre-order.png" , "coming-boost5.png"]
  },
];
