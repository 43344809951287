import React from "react";
import Button from "../../components/Button";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
const cardData = [
  {
    text: "B3 Bundles",
    img: "bundle-main.png",
    smImg: "bundle-sm-main.png",
    link: "/bundle",
    buttonText: "Chose Bundle",
  },
  {
    text: "B3 Bands",
    img: "bands-main.png",
    smImg: "bands-sm-main.png",
    link: "/bands",
    buttonText: "Chose Bands",
  },
  {
    text: "B3 Nutrition Products",
    img: "nutrition-products-main.png",
    smImg: "nutrition-products-sm-main.png",
    link: "/nutrition-products",
    buttonText: "Chose Product",
  },
  {
    text: "B3 U Fitness App",
    img: "b3-u-main.png",
    smImg: "b3-u-sm-main.png",
    link: "/become-a-member",
    buttonText: "Check Benefits",
  },
  {
    text: "Founder Opportunity",
    img: "founder-main.png",
    smImg: "founder-sm-main.png",
    link: "/business-opportunity",
    buttonText: "Founder Opportunity",
  },
  {
    text: "BFR Courses",
    img: "bfr-courses-main.png",
    smImg: "bfr-courses-sm-main.png",
    link: "/bfr-courses",
    buttonText: "BFR Courses",
  },
];

export default function Page() {
  return (
    <div className="bg-[#302b2b]">
      <img
        src={`./assets/images/landing-page-top-photo1.png`}
        alt="University of Southern California"
        className={`inset-0 h-full w-full object-cover hidden md:block `}
      />
      <img
        src={`./assets/images/mobile-app-header.png`}
        alt="University of Southern California"
        className={`inset-0 h-full w-full object-cover block md:hidden `}
      />
      <div className="grid gap-4 gap-y-12 px-4 md:gap-10 md:gap-14 md:gap-y-8 p-8 md:px-16 md:py-10 grid-cols-2 lg:grid-cols-3">
        {cardData.map((card, index) => (
          <div key={index} className="space-y-6">
            <Card {...card} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
}

function Card({ img, smImg, text, link, buttonText, index }) {
  return (
    <a
      href={link}
      className="transition duration-300 hover:scale-105 relative h-full isolate flex flex-col justify-end overflow-hidden rounded-2xl md:rounded-b-2xl md:rounded-t-[unset] mx-auto "
    >
      <img
        src={`./assets/images/${img}`}
        alt="University of Southern California"
        className="hidden md:block inset-0 w-full"
      />
      <img
        src={`./assets/images/${smImg}`}
        alt="University of Southern California"
        className={`block md:hidden inset-0 w-full`}
      />
      <div className="grid z-10 px-4 py-2 md:p-4 bg-[#00adef] h-[58px] md:h-auto md:min-h-[70px]">
        <div className="flex items-center gap-2 justify-between">
          <h3 className="text-sm md:text-xl font-bold text-black">{text}</h3>
          <div className="inline-block p-2 rounded-full bg-[#00adef] border border-white border-[1.5px] px-3 md:px-[15px]">
            <ArrowRightIcon
              fill="white"
              className="h-3 w-3 md:h-5 md:w-5"
              aria-hidden="true"
              color="white"
            />
          </div>
        </div>
      </div>
    </a>
  );
}
