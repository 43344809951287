import React from "react";
import Carousel from "../../components/UpdatedCarousel";
import AddToCart from "./AddToCart";

export const Details = ({ product }) => {
  return (
    <div className="flex flex-col">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-8 md:gap-12">
        <div className="flex flex-col !my-auto mt-2 md:mt-5 h-fit bg-[#302b2b] p-6 rounded-[1rem]">
          <h1 className="md:text-6xl text-4xl roboto-medium">
            <span className="text-white">B</span>
            <span className="text-[#00adef] mr-3">3</span>
            <span className="text-white">{product.productName}</span>
          </h1>
          <div className="roboto-regular py-4">
            <ul className="list-disc pl-4 pb-1">
              {product.details?.map((item, index) => (
                <li key={index} className="py-1 text-xl text-white">
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <AddToCart sku={product.addToCartSku} />
        </div>
        <div className="flex items-center">
          <Carousel
            className="!h-auto !m-0"
            swiperImages={product.images}
            allowExpand
          />
        </div>
      </div>
    </div>
  );
};
