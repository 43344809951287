import React, { Fragment } from "react";
import VideoButton from "./VideoButton";
import VimeoPlayer from "./VimeoPlayer";
import { Dialog, Transition } from "@headlessui/react";
import HandSvg from "../../public/assets/images/hand-pointer.svg";
import PlaySvg from "../../public/assets/images/play-button.svg";
import XMarkSvg from "../../public/assets/images/x-mark.svg";
const VideoModal = ({ open, setOpen , videoId }) => {
  return (
    <div className="flex justify-center relative">
      <VideoButton
        onClick={() => setOpen(true)}
        className="text-lg md:text-xl px-2 py-2 rounded-full roboto-regular relative shape w-[160px]"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
      >
        <img src={PlaySvg} alt="Play Icon" width="30" height="30" />
        <span className="mt-[1px]">Watch Video</span>
        <img
          src={HandSvg}
          alt="Hand Icon"
          className="ml-2 absolute mt-[28px] ml-[100px] z-10"
          width="30"
          height="30"
        />
        <div className="custom-div rounded-full"></div>
      </VideoButton>

      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white px-6 py-6 text-left align-middle shadow-xl transition-all relative">
                  <button
                    onClick={() => setOpen(false)}
                    className="absolute top-3 right-[1.15rem] text-gray-800"
                    aria-label="Close"
                  >
                    <img src={XMarkSvg} alt="X Icon" width="24" height="24" />
                  </button>

                  <VimeoPlayer videoId={videoId} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default VideoModal;
