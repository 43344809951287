import React, { useState, Fragment } from "react";
import B3Nutrition from "./B3Nutrition";
import Carousel from "../../components/UpdatedCarousel";
import AddToCart from "./AddToCart";
import Subscribe from "./Subscribe";
import Button from "../../components/Button";
import VideoModal from "../../components/VideoModal";

export const Details = ({ product }) => {
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const {
    productName,
    details,
    factsImage,
    comingSoon,
    addToCartSku,
    subscribePrice,
    subscribeSku,
    preOrder,
    preOrderSku,
    preOrderImage,
    bottomIcon,
    flavorImage,
    comingSoonText,
    images,
    videoId,
  } = product;
  return (
    <div className="flex flex-col">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-12">
        <div className="flex relative flex-col bg-[#302b2b] p-6 rounded-[1rem]">
          <h1 className="pb-4 pl-1 md:pb-0 md:text-6xl text-5xl roboto-medium">
            <span className="text-white">B</span>
            <span className="text-[#00adef] mr-3">3</span>
            <span className="text-white">{productName}</span>
          </h1>
          <B3Nutrition details={details} factsImage={factsImage} />
          {!comingSoon ? (
            <>
              <AddToCart sku={addToCartSku} />
              <Subscribe price={subscribePrice} sku={subscribeSku} />
            </>
          ) : preOrder ? (
            <>
              <AddToCart preOrder={preOrder} sku={preOrderSku} />
            </>
          ) : (
            <Button
              onClick={() => {}}
              className="text-xl !ml-0 mt-4 md:text-2xl py-2 rounded-full roboto-regular !w-fit"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              {comingSoonText}
            </Button>
          )}
          <div className="flex custom-wrap gap-0 md:gap-2 pt-4">
            {bottomIcon.map((image, index) => (
              <img
                key={index}
                alt="bottom Icon"
                src={image.src}
                className={`${image.className} mt-auto`}
              />
            ))}
          </div>
          <div className="w-[90px]">
            <img
              src={`./assets/images/${flavorImage}`}
              className="absolute w-[100px] right-[-15px] top-[-36px] md:w-[120px] md:right-[-30px] md:top-[-40px]"
            />
          </div>
        </div>
        <div className="block items-center">
          <Carousel
            className="!h-auto"
            swiperImages={preOrder ? preOrderImage : images}
            allowExpand
          />
          {videoId && (
            <VideoModal
              open={isOpenVideo}
              setOpen={setIsOpenVideo}
              videoId={videoId}
            />
          )}
        </div>
      </div>
    </div>
  );
};
