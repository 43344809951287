import Carousel from "../../components/UpdatedCarousel";
import { useState } from "react";
import AddToCartForm from "./AddToCartForm";
import VideoModal from "../../components/VideoModal";
export default function OpportunityBlock({
  title,
  component: Component,
  images,
  sku,
  saveAmount,
  videoId,
}) {
  const handleAddToCart = () => {
    localStorage.setItem("currentRoute", "website");
  };
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-4 md:gap-12">
        <div className="relative flex flex-col mt-8 md:mt-6 bg-[#302b2b] p-6 rounded-[1rem]">
        <h1 className="pb-4 md:pb-0 md:text-[2.7rem] leading-none text-2xl roboto-medium max-w-full w-[calc(100%-130px)] md:w-[calc(100%-190px)]">
            <span className="text-white block">{title}</span>
            <span className="text-2xl md:text-3xl text-[#00adef]">
              {saveAmount}
            </span>
          </h1>
          <img
            alt="member-logo"
            src="./assets/images/member-logo.png"
            className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[60px] md:top-[-35px] md:right-[85px]"
          />
          <img
            alt="30Day Icon"
            src="./assets/images/30days-free.png"
            className="w-[80px] h-[80px] md:w-[130px] md:h-[130px] absolute top-[-30px] right-[-20px] md:top-[-35px] md:right-[-45px]"
          />
          {Component}
          <AddToCartForm sku={sku} handleAddToCart={() => handleAddToCart()} />
          <div className="flex gap-4 mt-4">
            <div className="text-lg md:text-2xl text-white roboto-regular-italic my-auto">
              As low as $235 month with paypal credit with $0 down and 0%
              interest
            </div>
            <div className="flex flex-col ml-4">
              <img
                alt="paypal credit"
                src="./assets/images/paypal-credit-t.png"
                className="w-[170px] m-auto"
              />
            </div>
          </div>
        </div>

        <div className="block my-auto mb-6 md:mb-auto items-center">
          <Carousel className="!h-auto" swiperImages={images} allowExpand />
          {videoId && (
            <VideoModal
              open={isOpenVideo}
              setOpen={setIsOpenVideo}
              videoId={videoId}
            />
          )}
        </div>
      </div>
    </div>
  );
}
