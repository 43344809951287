export default function Details({ details, subHeading }) {
  return (
    <div className="roboto-regular">
      <p className="py-2 italic text-[#00adef] text-2xl text-white">
        {subHeading}
      </p>
      <ul className="list-disc pl-4 pb-3">
        {details.map((item, index) => (
          <li key={index} className="py-1 text-xl text-white">
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}
