import React from "react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";

const ErrorMessage = ({ message, onClose }) => {
  return (
    <div className="flex mt-4 items-center justify-between p-4 bg-red-100 text-red-700 border border-red-300 rounded-md">
      <div className="flex items-center">
        <ExclamationTriangleIcon className="w-5 h-5 mr-2" />
        <span className="text-sm font-medium">{message}</span>
      </div>
      <button
        onClick={onClose}
        className="text-red-700 hover:text-red-900 transition duration-150 ease-in-out pl-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 8.586L4.707 3.293a1 1 0 00-1.414 1.414L8.586 10l-5.293 5.293a1 1 0 001.414 1.414L10 11.414l5.293 5.293a1 1 0 001.414-1.414L11.414 10l5.293-5.293a1 1 0 00-1.414-1.414L10 8.586z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default ErrorMessage;
