import { Fragment, useState, useContext, useRef, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { getCart } from "../../utils/localstorage";
import { CartContext } from "../../contexts/CartContext";
import PRODUCTS from "../../../public/assets/products.json";

export default function SelectionAddToCart({ skuConfigurations, setOpen }) {
  const productTypes = skuConfigurations.map((config) => {
    const product = PRODUCTS.find((p) => p.Sku === config.Sku);
    return {
      ...config,
      ProductName: product?.ProductName ?? "Unknown Product",
      Price: product?.Price ?? 0,
    };
  });
  const notify = () => toast("Product is added!");
  const [selected, setSelected] = useState(productTypes[0]);
  const cartContext = useContext(CartContext);

  const handleSetChange = (newSet) => {
    setSelected(newSet);
  };

  const addProduct = () => {
    const cart = getCart();
    notify();
    setOpen(true);
    // Add ProdctType - SET
    const product = PRODUCTS.find((p) => p.Sku === selected.Sku);
    const existing = cart.find((p) => p.product.Sku === selected.Sku);
    if (existing) {
      existing.qty = (Number(existing.qty) || 0) + 1;
    } else {
      cart.push({ product: product, qty: 1 });
    }
    cartContext.save(cart);
  };

  return (
    <div className="flex flex-col">
      <div className="text-2xl md:text-3xl text-white roboto-regular">
        Choose size
      </div>
      <div className="md:flex flex-row items-center gap-2 mt-1 md:mt-3">
        <Listbox value={selected} onChange={handleSetChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
              <span className="block truncate">{selected.Size}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400 sm:text-2xl"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {productTypes.map((type, typeIdx) => (
                  <Listbox.Option
                    key={typeIdx}
                    className={() =>
                      `relative cursor-pointer select-none py-2 pl-10 hover:bg-amber-100 pr-4 ${
                        selected.Sku === type.Sku
                          ? "bg-amber-100 text-amber-900"
                          : "text-gray-900"
                      }`
                    }
                    value={type}
                  >
                    {({}) => (
                      <div className="flex flex-col text-base font-light">
                        <div>
                          <span
                            className={`block roboto-regular truncate ${
                              selected ? "font-normal" : "font-light"
                            }`}
                          >
                            {type.Size}
                          </span>
                          {selected.Sku === type.Sku ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-4 w-4 md:h-5 md:w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        {type.Additional && (
                          <div className="roboto-regular text-[#00adef] text-xs md:text-sm ">
                            Additional ${type.Additional}
                          </div>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="flex gap-4 py-4">
        <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
          ${selected.Price}
        </div>
        <Button
          type="button"
          onClick={addProduct}
          className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          ADD TO CART
        </Button>
      </div>
    </div>
  );
}
