import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import { Details } from "./Details";
import { gearProductsData } from "./productData";

export default function Gear() {
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <h1 className="pb-4 pl-1 md:py-4 md:text-6xl text-5xl roboto-medium">
        <span>B</span>
        <span className="text-[#00adef] mr-3">3</span>
        <span className="">Gear</span>
      </h1>
      <div className="flex flex-col gap-16">
        {gearProductsData.map((product) => {
          return <Details key={product.addToCartSku} product={product} />;
        })}
      </div>
      <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
        <NavLink key="b3" to="/misc-bands">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            <div className="inline-block pr-1 relative top-[3px]">
              <ArrowLeftIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
            {"Back"}
          </Button>
        </NavLink>
        <NavLink key="check-out" to="/check-out">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            Next
            <div className="inline-block pl-1 relative top-[3px]">
              <ArrowRightIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
          </Button>
        </NavLink>
      </div>
    </section>
  );
}
