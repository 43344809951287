export default function Details({ details }) {
  return (
    <div className="roboto-regular">
      <ul className="list-disc pl-4 pb-3">
        {details?.map((item, index) => (
          <li key={index} className="py-1 text-xl text-white">
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}
